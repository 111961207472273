import React, { useContext, useState } from "react";
import { AuthContext } from "../App";
function Login() {
  const [userId, setUserId] = useState("");
  const { login } = useContext(AuthContext);

  const handleLogin = () => {
    if (userId.trim()) {
      login(userId);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <div className="login-form">
          <input
            type="number"
            placeholder="Enter User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <button onClick={handleLogin}>Log In</button>
        </div>
      </div>
    </div>
  );
}

export default Login;
