import React, { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/Login";
import ProductList from "./components/ProductList";
export const AuthContext = createContext(null);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setIsAuthenticated(!!userId);
  }, []);

  const login = (userId) => {
    localStorage.setItem("userId", userId);
    setIsAuthenticated(true);
    navigate("/products");
  };

  const logout = () => {
    localStorage.removeItem("userId");
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/products" /> : <Login />}
        />
        <Route
          path="/products"
          element={isAuthenticated ? <ProductList /> : <Navigate to="/login" />}
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/products" : "/login"} />}
        />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
