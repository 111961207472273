import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";
import {
  SimpleSpanProcessor,
  WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";

let provider;

export function initTelemetry() {
  // Only initialize once
  if (provider) {
    return;
  }

  const providerConfig = {
    resource: new Resource({
      "service.name": "otel-browser-tests-react-client",
    }),
  };

  provider = new WebTracerProvider(providerConfig);

  const exporter = new OTLPTraceExporter({
    url: "https://collector-http.infrastack.ai/v1/traces",
    headers: {
      "infrastack-api-key":
        "sk-1644f1563a98f5fc445fec230f2a86efbb97c3abaf8bf5af",
    },
  });

  provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
  provider.addSpanProcessor(new UserIdSpanProcessor(getUserId));

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-fetch": {},
      }),
    ],
  });
}

function getUserId() {
  return localStorage.getItem("userId") || "anonymous";
}

class UserIdSpanProcessor {
  constructor(getUserId) {
    this.getUserId = getUserId;
  }

  onStart(span, parentContext) {
    const userId = this.getUserId();
    console.log("userId", userId);
    if (userId) {
      span.setAttribute("user_id", userId);
    }
  }

  // No-op on span end
  onEnd(span) {}

  // No-op on shutdown
  shutdown() {}
}
